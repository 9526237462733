<template>
  <div>
    <component
      :is="ghsComponent"
      :param.sync="popupParam"
    />
  </div>
</template>

<script>
export default {
  name: 'chem-ghs',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmChemMaterialId: '',
        subtabName: '',
        plantCd: null,
      }),
    },
    chemData: {
      type: Object,
      default: () => ({
        plantCd: null,  // 사업장코드
        mdmChemMaterialId: '',  // 화학자재 일련번호
        mdmChemMaterialMsdsId: '',
        revisionNum: '',
        groupId: '',
        sysRevision: 1,
        revisionContent: '제정',
        preMdmChemMaterialId: '',  // 이전 화학자재 일련번호
        materialCd: '',  // 자재코드
        mdmChemMaterialName: '',  // 화학자재 품명
        deliveryVendorCd: '',  // 협력사명
        mfgVendorCd: '',  // 제조사명
        usageCd: null,  // 용도
        usageEtc: '',  // 용도 기타
        inUnitCd: null,  // 입고단위
        inConversonFactor: '',  // 입고환산계수
        inConversonUnitCd: '',  // 입고환산단위
        msdsNo: '',  // MSDS번호
        emergTel: '',  // 긴급연락처
        makeAddress: '',  // 제조사주소
        specificGravity: '',  // 비중
        propertiesStateCd: null,  // 성상
        colorClass: '',  // 색상
        warehousingTypeCd: null,  // 입고형태
        freezingPoint: '',  // 어는점
        boilPoint: '',  // 끓는점
        flashPoint: '',  // 인화점
        firePoint: '',  // 발화점
        explosionLimit: '',  // 폭발한계상한하한
        vaporPressure: '',  // 증기압
        twaStandart: '',  // 노출기준TWA
        characteristicValue: '',  // 특성치(경구경치흡입제외)
        remarks: '',  // 비고
        useFlag: 'Y',  // 사용여부
        unusedReason: '', // 미사용 사유
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      // tab: 'chemGhs1',
      // tabItems: [
      //   { name: 'chemGhs1', icon: 'groups', label: '1. 구성물질별 GHS 분류데이터 (국가별)', component: () => import(`${'./chemGhs1.vue'}`) },
      //   { name: 'chemGhs2', icon: 'list', label: '2. 제품 GHS 혼합분류 결과', component: () => import(`${'./chemGhs2.vue'}`) },
      //   { name: 'chemGhs3', icon: 'list', label: '3. 제품 GHS 분류근거 DB', component: () => import(`${'./chemGhs3.vue'}`) },
      // ],
      ghsComponent: () => import(`${'./chemGhs2.vue'}`)
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
    },
  }
};
</script>